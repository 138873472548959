import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { useMediaQuery } from "@mui/material";
import { FONT_SIZE_DESKTOP_HEADING, FONT_SIZE_MOBILE_HEADING } from "shared";

function Announcement({ text, color, fontFamily }) {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <MKBox
            component="section"
            position="relative"
            py={6}
            px={{ xs: 0, lg: 0 }}
            mb={isMobile ? -8 : -8}
        >
            <Container>
                <Grid container justifyContent="center" mx="auto">
                    <MKTypography
                        variant="h3"
                        color={color}
                        gutterBottom
                        sx={{
                            textAlign: "center",
                            textShadow: "2px 2px 4px black",
                            fontSize: isMobile
                                ? FONT_SIZE_MOBILE_HEADING
                                : FONT_SIZE_DESKTOP_HEADING,
                            fontFamily: { fontFamily },
                        }}
                    >
                        {text}
                    </MKTypography>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Announcement;
