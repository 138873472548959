import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import {
    Tournament,
    Foundations,
    Prizes,
    Header,
    Announcement,
} from "sections";
import { MKBox, Logo, Section, StyledCard } from "components";
import { useHashScroll, findEventByName } from "shared";

function MainEvent() {
    useHashScroll();

    const event = findEventByName("Commander Paradise: 2nd Round");
    if (!event) return null;

    const formatDateRange = (startDate) => {
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 2);

        const formatDate = (date) => {
            return new Intl.DateTimeFormat("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            }).format(date);
        };

        return `del ${formatDate(startDate)} al ${formatDate(endDate)}`;
    };

    const dates = formatDateRange(event.date);

    return (
        <>
            <Helmet>
                <title>Evento Principal - Commander Paradise: 2nd Round</title>
                <meta
                    name="description"
                    content={`Detalles del evento principal del Commander Paradise: 2nd Round, incluyendo torneo, bases y premios.`}
                />
                <meta
                    name="keywords"
                    content="Magic, Evento Principal, Commander Paradise, Torneo"
                />
            </Helmet>
            <Header
                fontFamily={event.fontFamily}
                title={event.name}
                image={event.image}
            />
            <Grid
                container
                justifyContent="center"
                mx="auto"
                position="relative"
            >
                <Grid item xs={12} md={8} textAlign="center">
                    <MKBox
                        mt={{ xs: -2, md: -13 }}
                        sx={{ position: "relative", zIndex: 1 }}
                    >
                        <Logo logo={event.logo} />
                    </MKBox>
                </Grid>
            </Grid>
            <StyledCard>
                <Section id="announcement">
                    {" "}
                    <Announcement
                        color={event.colors.warning}
                        fontFamily={event.fontFamily}
                        text={dates}
                    />
                    <Announcement
                        color={event.colors.warning}
                        fontFamily={event.fontFamily}
                        text={`Plazas limitadas a ${event.maxCapacity}: ¡No te quedes sin la tuya!`}
                    />
                </Section>
                <Section id="tournament">
                    <Tournament
                        info={event.info}
                        poster={event.posters[0]}
                        backgroundColor1="black"
                        backgroundColor2="cerulean"
                        opacity1={0}
                        opacity2={0}
                        gradient1={98}
                        gradient2={100}
                        gradientAngle={180}
                    />
                </Section>
                <Section id="foundations">
                    <Foundations
                        foundations={event.foundations}
                        backgroundColor1="black"
                        backgroundColor2="cerulean"
                        opacity1={0}
                        opacity2={0}
                        gradient1={98}
                        gradient2={100}
                        gradientAngle={180}
                    />
                </Section>
                <Section id="prizes">
                    <Prizes event={event} />
                </Section>
            </StyledCard>
        </>
    );
}

export default MainEvent;
