const pachangaIVFoundations = `
Formato del torneo:
Día 1: Formato Suizo 32 jugadores a elegir entre Modern, Estándar, Legacy. 
Día 2: Formato Modern. Suizo + Top
Día 3: Formato Suizo 32 jugadores a elegir entre Modern, Estándar, Legacy, Vintage. 

Habrá torneos paralelos los 3 días (se pagan aparte) cuyos formatos se anunciarán en la web.
Cada formato tiene sus propias reglas y restricciones sobre qué cartas se pueden jugar, así que asegúrate de conocer las reglas del formato.

Registro:
El registro para los torneos se realizará a través de una plataforma online (se enviará un correo con el enlace). En caso de no disponer de dicha plataforma o si ocurre algún fallo informático, necesitarás una cuenta en Wizards of the Coast para participar en los torneos del Pachanga Tournament (la misma cuenta que utilizas para acceder a Magic Arena).

Lista de Mazos:
Debes presentar una lista de mazo antes del evento. Esta lista debe incluir todas las cartas en tu mazo y su cantidad. La lista de mazo se utiliza para verificar que tu mazo cumple con las reglas del formato y para asegurarse de que no cambies tu mazo durante el evento. Se presentará en la plataforma online o, en caso de no disponer de dicha plataforma o si ocurre un fallo informático, deberás traerla en papel.

Ubicación y Horarios:
Bungalows VistaFlor, Av. Touroperador Neckermann, 1A, 35100 Gran Canaria, España

Día 1: Comienzo del torneo a las 16:00 (recepción de jugadores desde las 14:00, pausa para la cena a las 20:00)
Día 2: Comienzo del torneo a las 10:00 (pausa para comida a las 13:00 y cena a las 20:00)
Día 3: Comienzo del torneo a las 10:00 (pausa para comida a las 13:00)

El horario de desayunos en el complejo es de 8:00 a 10:00.
El horario de almuerzo en el complejo es de 13:00 a 15:00.
El horario de cena en el complejo es de 18:30 a 21:00.
Asegúrate de conocer el horario y la ubicación del evento. Esto te ayudará a planificar cuánto tiempo necesitas para llegar, qué día y hora comenzará, y cuánto tiempo durará.

Reglas del torneo:
Antes de participar en un torneo, debes conocer las reglas. Esto incluye cómo se determina el ganador, las reglas de emparejamiento, las restricciones de tiempo, las reglas de conducta y cualquier otra regla importante. Los torneos del Pachanga Tournament se caracterizan por un ambiente amistoso y relajado. Los jugadores pueden conversar entre sí y ayudarse mutuamente en caso de dudas.

Emparejamiento:
Una vez que comience el torneo, se te emparejará con otro jugador. El emparejamiento se realiza normalmente según tu puntuación en el torneo. A medida que ganes o pierdas partidas, tu puntuación cambiará y te emparejarán con jugadores de puntuación similar. El emparejamiento podrás verlo en la pantalla que se encuentra en la zona de juego principal o en tu móvil mediante la aplicación utilizada.

Duración de la partida:
Las rondas en los torneos serán de 50 minutos, comenzando a contar 3 minutos después de asignar los emparejamientos. Si la partida no ha terminado en el tiempo asignado, el resultado se decidirá dando 5 últimos turnos o mediante otros medios establecidos por el formato.

Conducta del jugador:
Asegúrate de conocer las reglas de conducta del jugador antes de participar en un torneo. Estas reglas incluyen el uso del lenguaje, el respeto hacia los demás jugadores y jueces, y la prohibición de hacer trampas. El incumplimiento de las reglas de conducta puede resultar en la descalificación del torneo.

Premios:
Los premios pueden estar sujetos a cambios según el nivel de participación.

Políticas del complejo:
Se ruega respetar las normas de uso de las instalaciones y/o servicios indicados en los carteles informativos del establecimiento. El uso de la piscina es exclusivo para los jugadores del Pachanga Tournament y los huéspedes.
Las visitas NO están permitidas; no se permitirá el acceso a personas que no estén registradas en el hotel.
El complejo y la organización se reservan el derecho de admisión.
El Check-in se efectúa a partir de las 14:00 y el Check-out de 8:00 a 12:00.
No se permiten mascotas en el hotel.

Código de conducta:
Respeto: Muestra respeto hacia tus oponentes, jueces, espectadores y organizadores del evento. Trata a todos con cortesía y evita comentarios ofensivos o despectivos.
Comunicación: Comunícate de manera clara y educada. Asegúrate de que tus oponentes entiendan tus jugadas y pregúntales si tienes dudas sobre las suyas.
Limpieza y orden: Mantén tu área de juego limpia y ordenada. Asegúrate de que tus cartas estén en buen estado y que tu mazo esté bien mezclado.
Puntualidad: Llega a tiempo al evento y asegúrate de estar presente en tus partidas en el momento que se te indique. Si te retrasas o no puedes asistir a una partida, avisa al organizador del evento lo antes posible.
Prohibición de trampas: No hagas trampas. Esto incluye ocultar cartas, robar cartas, manipular dados o marcadores, o cualquier otra acción que te dé una ventaja injusta. La trampa puede resultar en la descalificación del torneo.
Uso de dispositivos electrónicos: No uses dispositivos electrónicos que puedan ayudarte durante el juego, como teléfonos móviles, calculadoras o dispositivos de almacenamiento de datos. Solo se permiten aquellos dispositivos aprobados por el juez del torneo, como un temporizador o una aplicación de vida.
Intimidación: No intimides ni acoses a tus oponentes. No está permitido hacer comentarios ofensivos sobre la raza, género, orientación sexual, religión u otros aspectos personales de tus oponentes.
Cumplimiento de las reglas: Cumple con las reglas del torneo y con las restricciones del formato. Si tienes alguna duda sobre las reglas, pregunta al juez del torneo o al organizador del evento.
Modo de juego justo: Juega de manera justa y sin engaños. No te aproveches de las reglas ambiguas o de los errores de tus oponentes. Si detectas un error en la partida, informa al juez del torneo o al organizador del evento.
Espíritu deportivo: Participa en el torneo con espíritu deportivo. Felicita a tus oponentes por las partidas ganadas y muestra respeto por sus habilidades de juego. Celebra las victorias con modestia y las derrotas con elegancia.

Compra de Entradas:
Las entradas para el Pachanga Tournament/Commander Paradise pueden adquirirse exclusivamente a través de nuestra página web oficial. Una vez completada la compra, recibirás una confirmación por correo electrónico con tu entrada electrónica.
Es responsabilidad del comprador verificar que la información proporcionada durante la compra es correcta. La organización no se hace responsable de errores en la información proporcionada por el comprador.

Política de Reembolsos:
Las entradas adquiridas para el Pachanga Tournament/Commander Paradise son no reembolsables. Sin embargo, en caso de fuerza mayor, la organización se reserva el derecho de decidir sobre la posibilidad de reembolsos.
Se considera fuerza mayor cualquier evento fuera del control razonable de la organización, incluyendo pero no limitado a desastres naturales, pandemias, actos de terrorismo, o cualquier otra circunstancia que haga imposible la realización del evento.

Cambios y Cancelaciones:
La organización se reserva el derecho de cambiar la fecha, la ubicación o cancelar el evento por razones justificadas. En caso de cancelación, se notificará a los asistentes a la mayor brevedad posible.
Los cambios o cancelaciones se comunicarán a través del correo electrónico proporcionado durante la compra y se publicarán en nuestra página web oficial.

Responsabilidad:
La organización no se hace responsable de pérdidas, daños o lesiones sufridas durante el evento, excepto en casos de negligencia grave por parte de la organización.

Protección de Datos:
Durante el proceso de compra, recopilamos datos personales como nombre, dirección de correo electrónico y detalles de pago. Estos datos se utilizan exclusivamente para procesar la compra y gestionar el evento.
Nos comprometemos a proteger la privacidad de nuestros asistentes y a cumplir con las leyes de protección de datos aplicables. No compartiremos tus datos personales con terceros sin tu consentimiento, salvo cuando sea necesario para la realización del evento.

Propiedad Intelectual:
Al asistir al evento, consientes que la organización pueda tomar fotografías y videos durante el evento y utilizarlos con fines promocionales.
Todos los derechos de propiedad intelectual relacionados con el evento, incluyendo pero no limitado a logos, marcas y contenido promocional, son propiedad de la organización o de sus respectivos propietarios.

Acceso al Evento:
Para ingresar al evento, los asistentes deben presentar su entrada electrónica y una identificación válida con foto. La entrada es personal e intransferible.
Está prohibido ingresar al evento con armas, drogas, sustancias ilegales, o cualquier otro objeto que la organización considere peligroso.

Para más información, podrás contactar por:
INSTAGRAM: @pachangatournament
CORREO: pachangatournament@gmail.com
`;

export { pachangaIVFoundations };
